import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { EuiButton, EuiSpacer } from '@elastic/eui';
import YoutubeVideo from "components/Internet_Access/Port_Forwarding/D-Link/YoutubeVideoCardForwarding";
import NavButtons from 'components/Internet_Access/Port_Forwarding/NavButtons';
import ForumBox from 'components/Internet_Access/Port_Forwarding/PrimaryBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Port Forwarding - D-Link",
  "path": "/Internet_Access/Port_Forwarding/D-Link/",
  "dateChanged": "2017-11-27",
  "author": "Mike Polinowski",
  "excerpt": "Connect to your Camera through the Internet",
  "image": "./IA_SearchThumb_Portforwarding_DLink.png",
  "social": "/images/Search/IA_SearchThumb_Portforwarding_DLink.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_IA-Port-Forwarding_white.webp",
  "chapter": "Remote Access"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='Port Forwarding - D-Link' dateChanged='2017-11-27' author='Mike Polinowski' tag='INSTAR IP Camera' description='Connect to your Camera through the Internet' image='/images/Search/IA_SearchThumb_Portforwarding_DLink.png' twitter='/images/Search/IA_SearchThumb_Portforwarding_DLink.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Fernzugriff/Port_Weiterleitung/D-Link/' locationFR='/fr/Internet_Access/Port_Forwarding/D-Link/' crumbLabel="D-Link" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "port-forwarding",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#port-forwarding",
        "aria-label": "port forwarding permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Port Forwarding`}</h1>
    <h2 {...{
      "id": "d-link-router",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#d-link-router",
        "aria-label": "d link router permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`D-Link Router`}</h2>
    <p>{`This is the 2nd step of the Port Forwarding, click `}<a parentName="p" {...{
        "href": "/en/Internet_Access/The_DDNS_Service/"
      }}>{`here`}</a>{` to get to Step I.`}</p>
    <YoutubeVideo mdxType="YoutubeVideo" />
    <h3 {...{
      "id": "step-1",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#step-1",
        "aria-label": "step 1 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Step 1`}</h3>
    <p>{`To access the user interface of your router, open the following address with your internet browser `}<em parentName="p">{`192.168.0.1`}</em>{`. The `}<strong parentName="p">{`default login`}</strong>{` is username = admin and password is left blank. Navigate to the Portweiterleitung in the Erweitert menu (s. below).`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "480px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/f735f34583ed4fd764e6fb37c4c1b959/7cc5e/Dlink_01en.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "64.78260869565217%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAIDBAX/xAAVAQEBAAAAAAAAAAAAAAAAAAABAv/aAAwDAQACEAMQAAABaR7s1hiCf//EABwQAAIBBQEAAAAAAAAAAAAAAAACAQMEERMyM//aAAgBAQABBQJYhzWM2JtfMqd//8QAFhEAAwAAAAAAAAAAAAAAAAAAAAES/9oACAEDAQE/AaZTP//EABYRAAMAAAAAAAAAAAAAAAAAAAABEv/aAAgBAgEBPwGUSj//xAAbEAACAgMBAAAAAAAAAAAAAAAAARFxAhAhMf/aAAgBAQAGPwKeI9xIhDvTs//EABwQAAICAgMAAAAAAAAAAAAAAAERACExUWGR8P/aAAgBAQABPyFyCgUiBF19Qh2FxL0eCaxCVYyn/9oADAMBAAIAAwAAABCQ3//EABYRAQEBAAAAAAAAAAAAAAAAAAABYf/aAAgBAwEBPxCLR//EABYRAQEBAAAAAAAAAAAAAAAAAAABYf/aAAgBAgEBPxCrB//EAB0QAQEAAgEFAAAAAAAAAAAAAAERACGRMUFh0eH/2gAIAQEAAT8QGBoBHnLpAefrBxcSnXvAJQ29l7MCtHDDHb2Of//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f735f34583ed4fd764e6fb37c4c1b959/e4706/Dlink_01en.avif 230w", "/en/static/f735f34583ed4fd764e6fb37c4c1b959/d1af7/Dlink_01en.avif 460w", "/en/static/f735f34583ed4fd764e6fb37c4c1b959/884e7/Dlink_01en.avif 480w"],
              "sizes": "(max-width: 480px) 100vw, 480px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/f735f34583ed4fd764e6fb37c4c1b959/a0b58/Dlink_01en.webp 230w", "/en/static/f735f34583ed4fd764e6fb37c4c1b959/bc10c/Dlink_01en.webp 460w", "/en/static/f735f34583ed4fd764e6fb37c4c1b959/d3be9/Dlink_01en.webp 480w"],
              "sizes": "(max-width: 480px) 100vw, 480px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f735f34583ed4fd764e6fb37c4c1b959/e83b4/Dlink_01en.jpg 230w", "/en/static/f735f34583ed4fd764e6fb37c4c1b959/e41a8/Dlink_01en.jpg 460w", "/en/static/f735f34583ed4fd764e6fb37c4c1b959/7cc5e/Dlink_01en.jpg 480w"],
              "sizes": "(max-width: 480px) 100vw, 480px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/f735f34583ed4fd764e6fb37c4c1b959/7cc5e/Dlink_01en.jpg",
              "alt": "Portforwarding D-Link",
              "title": "Portforwarding D-Link",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Go to Advanced/Portforwarding to set a new port forwarding rule for your camera.`}</p>
    <h3 {...{
      "id": "step-2",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#step-2",
        "aria-label": "step 2 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Step 2`}</h3>
    <p>{`Check the box in front of the service and give your camera a name in the Name field. Type your camera´s IP address into the field IP Address underneath. Set both the `}<strong parentName="p">{`Öffentlicher Port`}</strong>{` and `}<strong parentName="p">{`Privater Port`}</strong>{` to the port of your camera. For your first camera this is `}<em parentName="p">{`8081`}</em>{`, for your second camera it´s `}<em parentName="p">{`8082`}</em>{` etc. Then choose TCP as `}<strong parentName="p">{`Datenverkehrstyp`}</strong>{` and click on `}<strong parentName="p">{`Einstellungen speichern`}</strong>{` to save the service.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "480px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/4d464346768ef939b5c792390769a1e6/7cc5e/Dlink_02en.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "46.52173913043478%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAJABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAQCAwX/xAAVAQEBAAAAAAAAAAAAAAAAAAABAv/aAAwDAQACEAMQAAABtbWeHCIjP//EABoQAAEFAQAAAAAAAAAAAAAAAAIAAQMEMhL/2gAIAQEAAQUCgZyHlHqrhSb/AP/EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABkQAAMAAwAAAAAAAAAAAAAAAAABQRBxgf/aAAgBAQAGPwJ7pBncM//EAB0QAAICAQUAAAAAAAAAAAAAAAABETEhQVFxofD/2gAIAQEAAT8hYkzAYaFNqy7yhWuDtn//2gAMAwEAAgADAAAAEOj/AP/EABURAQEAAAAAAAAAAAAAAAAAAAEQ/9oACAEDAQE/EBZ//8QAFREBAQAAAAAAAAAAAAAAAAAAARD/2gAIAQIBAT8QQn//xAAeEAEAAgIBBQAAAAAAAAAAAAABADERYfAhQaGx0f/aAAgBAQABPxBoDMsvYg0A7dP2ZuuMMvc4Wk4m55n2z//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4d464346768ef939b5c792390769a1e6/e4706/Dlink_02en.avif 230w", "/en/static/4d464346768ef939b5c792390769a1e6/d1af7/Dlink_02en.avif 460w", "/en/static/4d464346768ef939b5c792390769a1e6/884e7/Dlink_02en.avif 480w"],
              "sizes": "(max-width: 480px) 100vw, 480px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/4d464346768ef939b5c792390769a1e6/a0b58/Dlink_02en.webp 230w", "/en/static/4d464346768ef939b5c792390769a1e6/bc10c/Dlink_02en.webp 460w", "/en/static/4d464346768ef939b5c792390769a1e6/d3be9/Dlink_02en.webp 480w"],
              "sizes": "(max-width: 480px) 100vw, 480px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4d464346768ef939b5c792390769a1e6/e83b4/Dlink_02en.jpg 230w", "/en/static/4d464346768ef939b5c792390769a1e6/e41a8/Dlink_02en.jpg 460w", "/en/static/4d464346768ef939b5c792390769a1e6/7cc5e/Dlink_02en.jpg 480w"],
              "sizes": "(max-width: 480px) 100vw, 480px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/4d464346768ef939b5c792390769a1e6/7cc5e/Dlink_02en.jpg",
              "alt": "Portforwarding D-Link",
              "title": "Portforwarding D-Link",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "step-3",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#step-3",
        "aria-label": "step 3 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Step 3`}</h3>
    <p>{`To be able to access the Flash Video as well, there has to be set a second Port Forwarding. Therefore, just follow the manual of this step, with the only difference to set the Flash (RTMP) Port instead. So for your first camera all ports are set to 1935, for your second camera it´s 1936, etc.`}</p>
    <p>{`Now all Port Forwardings for your camera are set in the router. You can now continue with the next step, therefore follow this Link:`}</p>
    <h2 {...{
      "id": "step-iii--the-cameras-ddns-address-and-the-remote-access",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#step-iii--the-cameras-ddns-address-and-the-remote-access",
        "aria-label": "step iii  the cameras ddns address and the remote access permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Step III : The Camera´s DDNS-Address and the Remote-Access`}</h2>
    <EuiSpacer mdxType="EuiSpacer" />
    <Link to="/Internet_Access/Mobile_Access/" mdxType="Link"><EuiButton fill mdxType="EuiButton">Step III</EuiButton></Link>
    <EuiSpacer mdxType="EuiSpacer" />
    <ForumBox mdxType="ForumBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      